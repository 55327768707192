import React from 'react';
import { IOrder, IOrderItem } from '../../../store/types/Orders';
import './OrderItem.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/configureStore';
import Spinner from '../../UI/Spinner/Spinner';
import * as actions from '../../../store/actions/index';

interface IProps {
    orderItem: IOrderItem;
    order: IOrder;
}

const OrderItem: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const onImageClick = (item: IOrderItem) => {
        // console.log('on image click orderItem', item);
        if (item.type === 'jpg') {
            if (item.imageData) {
                dispatch(actions.showLargeImage(item.imageData));
            }
        }
    };

    const imageData = useSelector((state: AppState) => {
        return state.orders.allOrders[props.order.orderId].items.map(
            (item: any, index: number) => {
                if (
                    item.itemId === props.orderItem.itemId &&
                    props.orderItem.type === 'jpg' &&
                    item.imageData
                ) {
                    return (
                        <div
                            className="orderItemContainer"
                            onClick={() => onImageClick(item)}
                            style={{ cursor: 'pointer' }}
                            key={index}
                        >
                            <img
                                src={'data:image/jpeg;base64,' + item.imageData}
                                className="prescriptionImage"
                                alt="prescription"
                            />
                            {props.orderItem.quantity ? (
                                <div
                                    style={{
                                        marginTop: 5,
                                        fontSize: 14,
                                        fontWeight: 400,
                                    }}
                                >
                                    Menge: {props.orderItem.quantity}
                                </div>
                            ) : null}
                        </div>
                    );
                }
                return null;
            },
        );
    });

    const isImageLoading = useSelector(
        (state: AppState) => state.orders.orderImagesDownloading,
    );

    let text: string | any = props.orderItem.text;
    if (text) {
        text = text.split('\n').map((item: string, key: number) => {
            return (
                <span key={key} className="orderText">
                    {item}
                    <br />
                    {props.orderItem.quantity ? (
                        <span
                            style={{
                                marginTop: 5,
                                fontSize: 14,
                                fontWeight: 400,
                            }}
                        >
                            Menge: {props.orderItem.quantity}
                        </span>
                    ) : null}
                </span>
            );
        });
    }

    if (text) {
        return <div style={{ padding: 10 }}>{text}</div>;
    } else if (imageData) {
        return isImageLoading ? <Spinner /> : imageData;
    } else {
        return <div />;
    }
};

export default OrderItem;
