import { Auth } from '../types/Auth';
import { AuthActions } from '../types/authActionTypes';
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
const initialState: Auth = {
    loginToken: null,
    pharmacyName: null,
    pharmacyId: null,
    pharmacyIdf: null,
    pharmacyCity: null,
    pharmacyZip: null,
    isPrivateKeyAvailable: true,
    isPrivateKeyValid: true,
    isPublicKeyFoundInServer: true,
    rsaKeyCreating: false,
    rsaKeyLoading: false,
    showBackupKeyView: false,
    showCsafeView: false,
    privateKey: null,
    error: null,
    loading: false,
    showBackupKeyPrompt: false,
};

const authStart = (state: Auth, action: any) => {
    return updateObject(state, { error: null, loading: true });
};

const authSuccess = (state: Auth, action: any) => {
    return updateObject(state, {
        loginToken: action.loginToken,
        pharmacyName: action.pharmacyName,
        pharmacyId: action.pharmacyId,
        error: null,
        loading: false,
    });
};

const authFail = (state: Auth, action: any) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
    });
};

const authLogout = (state: Auth, action: any) => {
    return updateObject(state, { loginToken: null });
};

const privateKeyFound = (state: Auth, action: any) => {
    return updateObject(state, {isPrivateKeyAvailable: true})
};

const privateKeyNotFound = (state: Auth, action: any) => {
    return updateObject(state, {isPrivateKeyAvailable: false})
};

const privateKeyInvalid = (state: Auth, action: any) => {
    return updateObject(state, { isPrivateKeyValid: false });
};

const publicKeyNotFound = (state: Auth, action: any) => {
    return updateObject(state, {isPublicKeyFoundInServer: false});
};

const publicKeyFound = (state: Auth, action: any) => {
    return updateObject(state, {isPublicKeyFoundInServer: true});
};

const rsaKeyLoading = (state: Auth, action: any) => {
    return updateObject(state, { rsaKeyLoading: true });
};

const rsaKeyLoadingDone = (state: Auth, action: any) => {
    return updateObject(state, { rsaKeyLoading: false });
};

const showBackupKeyView = (state: Auth, action: any) => {
    return updateObject(state, {showBackupKeyView: true});
};

const hideBackupKeyView = (state: Auth, action: any) => {
    localStorage.setItem('isBackupDone', '1');
    return updateObject(state, {showBackupKeyView: false});
};

const setShowBackupKeyPrompt = (state: Auth, action: any) => {
    return updateObject(state, { showBackupKeyPrompt: action.value} );
};

const setPharmacyData = (state: Auth, action: any) => {
    return updateObject(state, {
        pharmacyIdf: action.pharmacyIdf,
        pharmacyCity: action.pharmacyCity,
        pharmacyZip: action.pharmacyZip
    })
};

const stateCreateRSAKey = (state: Auth, action: any) => {
    return updateObject(state, {rsaKeyCreating: true});
};

const endCreateRSAKey = (state: Auth, action: any) => {
    return updateObject(state, {rsaKeyCreating: false});
};

const showCsafeModal = (state: Auth, action: any) => {
    return updateObject(state, {showCsafeView: true});
};

const hideCsafeModal = (state: Auth, action: any) => {
    return updateObject(state, {showCsafeView: false});
};

const savePrivateKey = (state: Auth, action: any) => {
    return updateObject(state, {privateKey: action.privateKey});
};


const reducer = (state = initialState, action: AuthActions): Auth => {
    switch (action.type) {
        case actionTypes.AUTH_START:
            return authStart(state, action);
        case actionTypes.AUTH_SUCCESS:
            return authSuccess(state, action);
        case actionTypes.AUTH_FAIL:
            return authFail(state, action);
        case actionTypes.AUTH_LOGOUT:
            return authLogout(state, action);
        case actionTypes.PRIVATE_KEY_FOUND:
            return privateKeyFound(state, action);
        case actionTypes.PRIVATE_KEY_NOT_FOUND:
            return privateKeyNotFound(state, action);
        case actionTypes.PRIVATE_KEY_INVALID:
            return privateKeyInvalid(state, action);
        case actionTypes.RSA_KEY_LOADING:
            return rsaKeyLoading(state, action);
        case actionTypes.RSA_KEY_LOADING_DONE:
            return rsaKeyLoadingDone(state, action);
        case actionTypes.SHOW_BACKUP_KEY_VIEW:
            return showBackupKeyView(state, action);
        case actionTypes.SHOW_BACKUP_PROMPT_KEY:
            return setShowBackupKeyPrompt(state, action);
        case actionTypes.HIDE_BACKUP_KEY_VIEW:
            return hideBackupKeyView(state, action);
        case actionTypes.PUBLIC_KEY_NOT_FOUND:
            return publicKeyNotFound(state, action);
        case actionTypes.PUBLIC_KEY_FOUND:
            return publicKeyFound(state, action);
        case actionTypes.SET_PHARMACY_DATA:
            return setPharmacyData(state, action);
        case actionTypes.START_CREATE_RSA_KEY:
            return stateCreateRSAKey(state, action);
        case actionTypes.END_CREATE_RSA_KEY:
            return endCreateRSAKey(state, action);
        case actionTypes.SHOW_CSAFE_MODAL:
            return showCsafeModal(state, action);
        case actionTypes.HIDE_CSAFE_MODAL:
            return hideCsafeModal(state, action);
        case actionTypes.SAVE_PRIVATE_KEY:
            return savePrivateKey(state, action);
        default:
            return state;
    }
};

export default reducer;
