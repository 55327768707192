import React from 'react';
import Modal from '../../UI/Modal/Modal';
import OptionItem from '../../UI/OptionItem/OptionItem';
import { getStatusOptions } from '../../../shared/orderHelper';
import { IStatusOption } from '../../../store/types/Orders';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../../store/configureStore';
import * as actions from '../../../store/actions/index';
import './OrderStatusChange.scss';

interface IProps {
    selectedOrderId: number;
}

const OrderStatusChange: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const selectedOrderId = useSelector((state: AppState) => {
        return state.orders.selectedOrderId;
    });
    const selectedOption = useSelector((state: AppState) => {
        return selectedOrderId >= 0
            ? state.orders.allOrders[selectedOrderId].status
            : -1;
    });
    const showChangeStatus = useSelector((state: AppState) => {
        return state.orders.showChangeStatus;
    });
    const selectedOrder = useSelector(
        (state: AppState) => state.orders.allOrders[selectedOrderId],
    );
    const changeStatusInformUser = useSelector(
        (state: AppState) => state.orders.changeStatusInformUser,
    );
    const optionClicked = (value: string) => {
        if (selectedOrderId >= 0) {
            dispatch(
                actions.sendStatus(
                    selectedOrder,
                    parseInt(value, 10),
                    !changeStatusInformUser,
                ),
            );
            dispatch(actions.hideChangeStatus());
        }
    };
    const onOutsideClick = () => {
        dispatch(actions.hideChangeStatus());
    };

    let options = getStatusOptions().map(
        (value: IStatusOption | null, index: number) => {
            if (value) {
                return (
                    <OptionItem
                        key={index}
                        isSelected={selectedOption === value.type}
                        text={value.text}
                        value={value.type.toString()}
                        onClick={optionClicked}
                    />
                );
            } else {
                return <div key={index} style={{ height: 22 }} />;
            }
        },
    );
    return (
        <span className="orderStatusChangeContainer">
            <Modal
                show={showChangeStatus}
                modalClosed={onOutsideClick}
                className="modalStyle"
            >
                <div className="title">Status ändern</div>
                {options}
            </Modal>
        </span>
    );
};

export default OrderStatusChange;
