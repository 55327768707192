import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/configureStore";
import * as actions from "../../../store/actions/index";
import "./ViewSelector.scss";
import {IOrder} from "../../../store/types/Orders";
import {IChatBucket} from "../../../store/types/Chat";

interface IProps {
    selectionChanged?: () => void;
}

const ViewSelector: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    let showChatListView = useSelector((state: AppState) => state.chats.showChatView);

    const badgeCount = useSelector((state: AppState) => {
        let counter = 0;
        let allOrders: IOrder[] = state.orders.allOrders;
        for (let key in allOrders) {
            if (allOrders.hasOwnProperty(key)) {
                let isUnreadMessageAvailable = false;
                for (let i = 0; i < allOrders[key].messages.length; i++) {
                    if (!allOrders[key].messages[i].readByPharmacy) {
                        isUnreadMessageAvailable = true;
                        break;
                    }
                }

                if (allOrders[key].hasImportantMessage || isUnreadMessageAvailable) {
                    counter = counter + 1;
                }
            }
        }
        return counter;
    });

    const totalUnreadMessageCount = useSelector((state: AppState) => {
        let counter = 0;
        let allChats: { [key: string]: IChatBucket } = state.chats.chatBucketList;
        for (let key in allChats) {
            if (allChats.hasOwnProperty(key)) {
                if (allChats[key].status !== 1) {
                    counter = counter + allChats[key].unreadElements;
                }
            }
        }
        return counter;
    });
    const hideChat = () => {
        dispatch(actions.hideChatView());
        if (props.selectionChanged) {
            props.selectionChanged()
        }
    };
    const showChat = () => {
        dispatch(actions.toggleShowChatView());
        if (props.selectionChanged) {
            props.selectionChanged();
        }
    };

    return (
        <div className="selectViewContainer">
            {/* ======= Orders ======== */}
            <div className={!showChatListView ? 'activeView' : ''} style={{position: "relative"}} onClick={hideChat}>
                Bestellungen {badgeCount > 0 && (<span className='badge'>{badgeCount > 9 ? '+9' : badgeCount}</span>)}
            </div>
            {/* ======= Chat ========== */}
            <div className={showChatListView ? 'activeView' : ''} onClick={showChat}>
                Chat
                {totalUnreadMessageCount > 0 && (<span className='badge'>{totalUnreadMessageCount > 9 ? '+9' : totalUnreadMessageCount}</span>)}
            </div>
        </div>
    );
};

export default ViewSelector;
