import * as React from 'react';
import "./ChatListEntry.scss";


interface IProps {
    customerName: string;
    date: string;
    status?: string;
    isSelected?: boolean;
    onSelectChatBucket: () => void;
    lastMessage?: string;
    isChatSelected: boolean;
    noOfUnreadMsg: number;
}

const ChatListEntry: React.FC<IProps> = (props: IProps) => {
    return (

        <div className="chatListEntryContainer"
             onClick={props.onSelectChatBucket}
             style={props.isChatSelected ? { backgroundColor: '#f3f4f6' } : {}}
        >
            <div>
                <div className="topRow">
                    <div style={{width: 20, paddingLeft: 5}} />
                    <span className="customerName">{props.customerName}</span>
                    <span className="date">{props.date}</span>
                </div>
                <div className="bottomRow">
                    <div className="lastMessage">
                        {props.lastMessage}
                    </div>
                    {/* no of unread message badge */}
                    {props.noOfUnreadMsg > 0 && (
                        <span className='unreadBadge'>
                        {props.noOfUnreadMsg > 9 ? '9+' : props.noOfUnreadMsg }
                    </span>
                    )}
                </div>
            </div>

        </div>
        );
};

export default ChatListEntry;
