import React from 'react';

// local imports
import "./Orders.scss";
import OrderList from "./OrderList/OrderList";
import OrderView from "./OrderView/OrderView";

interface IProps {
}

const Orders: React.FC<IProps> = () => {
    return (
        <div className="ordersContainer">
            <OrderList />
            <OrderView />
        </div>
    );
};

export default Orders;
