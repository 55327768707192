import React from 'react';

// local imports
import {IOrder, IOrderItem} from "../../../store/types/Orders";
import OrderItem from "../OrderItem/OrderItem";
import "./OrderItemsView.scss";
import {formatDateForOrderList} from "../../../shared/utility";
import {formatPayback} from "../../../shared/orderHelper";

interface IProps {
    order: IOrder
}

const OrderItemsView: React.FC<IProps> = (props:IProps) => {
    let orderItemsListImages = props.order.items.map((orderItem: IOrderItem, index: number) => {
        if (orderItem.type === 'jpg') {
            return <OrderItem orderItem={orderItem} order={props.order} key={index}/>;
        } else {
            return null;
        }
    });

    let orderItemsListTexts = props.order.items.map((orderItem: IOrderItem, index: number) => {
        if (orderItem.type !== 'jpg') {
            return <OrderItem orderItem={orderItem} order={props.order} key={index}/>;
        } else {
            return null;
        }
    });
    return (
        <div className="orderItemsMainWrapper">
            <div className="orderItemsViewContainer">
                <div className="itemsContainer">
                    <div className="title" style={{userSelect: 'auto'}}>
                        VORBESTELLUNG
                    </div>
                    {props.order.paybackCustomerId &&
                    <div className="paybackContainer">
                        <img src={require('../../../assets/images/payback.png')} style={{marginRight: 8}} alt='Payback'/>{formatPayback(props.order.paybackCustomerId)}
                    </div>}
                    <div className="imagesContainer">
                        {orderItemsListImages}
                    </div>
                    {orderItemsListTexts}
                </div>
                <div className="spacer"/>
            </div>
            <div className="orderDate">
                {formatDateForOrderList(props.order.date)}
            </div>
        </div>
    );
};

export default OrderItemsView;
