import React, {useEffect, useState} from 'react';
import "./LargeImageView.scss";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/configureStore";
import * as actions from "../../../store/actions/index";

interface IProps {
}

const LargeImageView:React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const show = useSelector((state: AppState) => state.orders.showLargeImage);
    const imageData = useSelector((state: AppState) => state.orders.largeImageData);
    const [rotateDeg, setRotateDeg] = useState(0);
    useEffect(() => {
        setRotateDeg(0);
    }, [show]);
    const onClick = () => {
        dispatch(actions.hideLargeImage());
    };
    return (
        <div className="largeImageViewContainer" >
            <div className="container">
                <div className="content" onClick={onClick}>
                    {imageData &&
                    <div className="imageContainer">
                        <img src={'data:image/jpeg;base64,' + imageData} className="image" style={{transform: 'rotate(' + rotateDeg + 'deg)', transition: 'all 0.30s ease-in-out'}} alt=''/>
                    </div>}
                </div>
                <div style={{position: 'absolute', left: '50%', bottom: 24}}>
                    <div style={{position: 'relative', left: '-50%', background: 'rgba(0, 0, 0, 0.7)', borderRadius: 6}}>
                        <img src={require('../../../assets/images/rotateleft.png')} alt={''} style={{cursor: 'pointer', margin: 12, marginBottom: 8}} onClick={() => {setRotateDeg(rotateDeg - 90)}} />
                        <img src={require('../../../assets/images/rotateright.png')} alt={''} style={{cursor: 'pointer', margin: 12, marginBottom: 8}} onClick={() => {setRotateDeg(rotateDeg + 90)}}/>
                    </div>
                </div>
                <div style={{position: 'absolute', right: 24, top: 24}} onClick={onClick}>
                    <div style={{position: 'relative', left: '-50%', background: 'rgba(0, 0, 0, 0.7)', borderRadius: 6}}>
                        <img src={require('../../../assets/images/close.png')} alt={''} style={{cursor: 'pointer', margin: 12, marginBottom: 8}}/>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default LargeImageView;
