import React from 'react';
import {useDispatch} from "react-redux";
import * as actions from "../../../store/actions/index";
import {store} from "../../../store/configureStore";

// local imports
import "./OrderMessagesView.scss";
import {IOrder, IOrderMessage} from "../../../store/types/Orders";
import OrderMessage from "../OrderMessage/OrderMessage";
import {getMessageText, getMessageTitle} from "../../../shared/orderHelper";

interface IProps {
    order: IOrder
}

const OrderMessagesView: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    let messageItems = [...props.order.messages,...props.order.additionalMessages].map((message: IOrderMessage, index: number) => {
        if (message.type === 'image' && message.fileToken) {
            let state: any = store.getState();
            let imageByFileToken =  state.orders.imagesByFileToken[message.fileToken];
            if (!imageByFileToken || imageByFileToken.loadStatus === 'notLoaded') {
                setTimeout(() => {
                    dispatch(actions.loadMessageImage(props.order, message));
                }, 0);
            }
        }
        return (<OrderMessage key={index} text={getMessageText(message)} from={message.from}
                              title={getMessageTitle(message, props.order)} date={message.date}
                              message={message} order={props.order}
        />)
    });
    return (
        <div className="orderMessagesViewContainer">
            {messageItems}
        </div>
    );
};

export default OrderMessagesView;
