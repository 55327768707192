import React from 'react';
import Modal from "../../UI/Modal/Modal";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/configureStore";
import * as actions from "../../../store/actions/index";
import "./OrderFilter.scss";
import {IOrderSortMode, IStatusOption, TSortMode} from "../../../store/types/Orders";
import {getStatusFilters, getStatusSortings} from "../../../shared/orderHelper";
import OptionItem from "../../UI/OptionItem/OptionItem";

interface IProps {

}

const OrderFilter:React.FC<IProps> = (props: IProps) => {
    const showFilterStatus = useSelector((state: AppState) => state.orders.showFilterStatus);
    const activeOrderFilters = useSelector((state: AppState) => state.orders.activeOrdersFilters);
    const activeSortMode = useSelector((state: AppState) => state.orders.sortMode);
    const dispatch = useDispatch();
    const hideFilter = () => dispatch(actions.hideFilterStatus());
    const onSortingClicked = (value: TSortMode) => dispatch(actions.setOrderSortMode(value));
    const onFilterClicked = (value: number) => dispatch(actions.toggleActiveOrderFilter(value));

    let sortings = getStatusSortings().map((value: IOrderSortMode, index: number) => {
        if (value) {
            return (
              <OptionItem
                  key={index}
                  isSelected={activeSortMode === value.type}
                  value={value.type.toString()}
                  text={value.text}
                  onClick={() => onSortingClicked(value.type)}
              />
            );
        } else {
            return (<div key={index} className='emptyRow'/>);
        }
    });

    let options = getStatusFilters().map((value: (IStatusOption|null), index: number) => {
        if (value) {
            return (<OptionItem
                key={index}
                isSelected={(activeOrderFilters.indexOf(value.type) > -1)}
                text={value.text}
                value={value.type.toString()}
                onClick={() => onFilterClicked(value.type)} />);
        } else {
            return (<div key={index} className='emptyRow'/>);
        }
    });
    return (
        <div className='orderFilterContainer'>
            <Modal
                show={showFilterStatus}
                modalClosed={hideFilter}
            >
                <div className="title">Listenansicht</div>
                <div className='optionBlock'>
                    <div className='optionTitle'>Sortiert nach</div>
                    <div>{sortings}</div>
                </div>
                <div className='optionBlock'>
                    <div className='optionTitle'>Einblenden</div>
                    <div>{options}</div>
                </div>
            </Modal>
        </div>
    );
};

export default OrderFilter;
