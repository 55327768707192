import dateFormat from 'dateformat';
import { IOrder } from '../store/types/Orders';
import {
    getMessageImageTag,
    getMessageText,
    getMessageTitle,
} from './orderHelper';

export class PrintController {
    getHTML(order: IOrder, withChat: boolean): string {
        let result = `<!DOCTYPE html>
<html lang="de">
<head>
<style>
body {
    font-family: 'Arial', sans-serif;
}
h1 {
    font-size: 14pt;
}
img {
    max-height: 500px;
    max-width: 500px;
}
</style>
</head>
<body>
<h1>Vorbestellung vom ${dateFormat(order.date, 'HH:MM "Uhr |" dd.mm.yy')}</h1>
${order.items
    .map(orderItem => {
        if (orderItem.type === 'jpg') {
            return `<img src='data:image/jpeg;base64,${orderItem.imageData}' alt=""><br>\n`;
        } else if (orderItem.text) {
            return orderItem.text.replace('\n', '<br>') + '<br>\n';
        } else {
            return '';
        }
    })
    .join('')}`;

        if (withChat) {
            result += `<h1>Nachrichten</h1>
${order.messages
    .map(message => {
        return `<div style={{marginTop: 12}}>${getMessageTitle(
            message,
            order,
        )}: ${getMessageText(message)}${getMessageImageTag(message, true)}${
            message.date
                ? '<br>(' +
                  dateFormat(message.date, 'HH:MM "Uhr |" dd.mm.yy') +
                  ')'
                : ''
        }</div>`;
    })
    .join('')}
            `;
        } else {
            result += '<br/>Besteller: ' + order.user.name + '<br/>';
        }
        result += `</body>
        </html>`;

        return result;
    }
}

const printController = new PrintController();
export default printController;
