import React, {useState} from 'react';
import './OrderListEntry.scss';
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../../store/actions/index";
import {AppState} from "../../../store/configureStore";

interface IProps {
    customerName: string;
    date: string;
    status: string;
    isSelected: boolean;
    onSelectOrder: () => void;
    hasImportantMessage: boolean;
    newMessagesCount: number;
    orderStatus: number;
    isDeleteEnable: boolean;
    orderId: number;
}

const OrderListEntry: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const [orderForDelete, setOrderForDelete] = useState(true);
    const multiSelectedOrderIds = useSelector((state: AppState) => state.orders.multiSelectedOrderIds);
    const updateDeleteState = () => {
        setOrderForDelete(!orderForDelete);

        if (multiSelectedOrderIds.includes(props.orderId)) {
            dispatch(actions.removeOrderForDelete(props.orderId));
        } else {
            dispatch(actions.addOrderForDelete(props.orderId));
        }
    };
    return (
        <div className="orderListEntryContainer" onClick={props.onSelectOrder} style={props.isSelected ? {backgroundColor: '#eee'} : {}}>

            <div>
                <div className="topRow">
                    <div style={{width: 20, paddingLeft: 5}}>
                        {
                            props.isDeleteEnable && props.orderStatus >= 50 ?
                                (
                                    <input type="checkbox"
                                           style={{margin: 0}}
                                           onClick={(event: any) => { event.stopPropagation();}}
                                           onChange={updateDeleteState}
                                    />
                                ) :
                                props.isDeleteEnable && (
                                    <input type="checkbox"
                                        style={{margin: 0}}
                                        disabled
                                    />
                                )
                        }
                    </div>
                    <span className="customerName">{props.customerName}</span>
                    <span className="date">{props.date}</span>
                </div>
                <div className="status">
                    {props.status}
                </div>
            </div>



            {props.hasImportantMessage &&
            <div style={{backgroundColor: '#ffbf3a', position: 'absolute', top: 0, right: 0, height: '100%', width: 8}} />}
            {!props.hasImportantMessage && props.newMessagesCount > 0 &&
            <div style={{backgroundColor: '#a9cfff', position: 'absolute', top: 0, right: 0, height: '100%', width: 8}} />}
        </div>
    );
};

export default OrderListEntry;
