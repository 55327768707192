import React from 'react';
import {connect} from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import {AppState, store} from './store/configureStore';
import { Auth } from './store/types/Auth';
import { AuthActions } from './store/types/authActionTypes';
import * as actions from './store/actions/index';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

// component imports
import Login from "./components/Auth/Login/Login";
import Logout from "./components/Auth/Logout/Logout";
import Dashboard from "./containers/Dashboard/Dashboard";

// fontawesome icon
import { library } from '@fortawesome/fontawesome-svg-core';
import { faKey, faPrint, faExternalLinkAlt, faSignOutAlt, faCaretDown, faLifeRing, faFilter, faTrash, faCheck, faTimes, faUserShield, faSpinner } from '@fortawesome/free-solid-svg-icons';
library.add(faKey, faPrint, faExternalLinkAlt, faSignOutAlt, faCaretDown, faLifeRing, faFilter, faTrash, faCheck, faTimes, faUserShield, faSpinner );

interface AppPageState {}
type Props = LinkDispatchProps & LinkStateProps;


class App extends React.Component<Props, AppPageState> {

    async componentDidMount() {
        this.props.onTryAutoLogin();
        this.props.checkShowBackupPrompt();
        document.title = "Vitus Web Client"


    }

    render() {
        let routes = (
            <Routes>
                <Route path="/" element={<Login />} />
            </Routes>
        );

        if (!this.props.authLoading && this.props.loginToken !== null) {
            routes = (
                <Routes>
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/dashboard" element={<Dashboard />}/>
                    <Route path="/" element={<Dashboard />} />
                </Routes>
            );
        }
        console.log('routes', routes);
        return (
                <BrowserRouter>
                    {routes}
                </BrowserRouter>
        );
    }
}

interface LinkStateProps {
    authLoading: Auth['loading'],
    loginToken: Auth['loginToken']
}
interface LinkDispatchProps {
    onTryAutoLogin: () => void;
    checkShowBackupPrompt: () => void;
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
    authLoading: state.auth.loading,
    loginToken: state.auth.loginToken
});

const mapDispatchToProps = ( dispatch: ThunkDispatch<any, any, AuthActions> ): LinkDispatchProps => ({
    onTryAutoLogin: () => { dispatch(actions.authCheckState()); },
    checkShowBackupPrompt: () => {
        const PromptFlagInStorage = localStorage.getItem('showBackupKeyPrompt');
            if (!PromptFlagInStorage || PromptFlagInStorage === '1') {
                dispatch(actions.setShowBackupKeyPrompt(true));
            }
        },
});

export default connect( mapStateToProps, mapDispatchToProps )(App);
