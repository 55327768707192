import React, {useEffect} from 'react';

import "./OrderMessage.scss";
import {IOrder, IOrderMessage, TChatFrom, TMessageAnswer} from "../../../store/types/Orders";
import {store} from "../../../store/configureStore";
import {formatDateForOrderList} from "../../../shared/utility";
import {useDispatch} from "react-redux";
import * as actions from "../../../store/actions/index";

interface IProps {
    order: IOrder;
    message: IOrderMessage;
    title: string;
    text?: string;
    from: TChatFrom;
    date?: Date;
}

const OrderMessage: React.FC<IProps> = (props: IProps) => {
    const { message, title, text, from, date } = props;
    let loadStatus: any;
    let imageData: any;
    if (props.message.type === 'image' && props.message.fileToken) {
        let state: any = store.getState();
        let imageByFileToken =  state.orders.imagesByFileToken[props.message.fileToken];
        if (props.message.fileToken && imageByFileToken)  {
                loadStatus = imageByFileToken.loadStatus;
                imageData = imageByFileToken.imageData;
        }
    }

    let styleName = 'systemBubble';
    let left = true;
    if (message.type === 'question') {
        styleName = 'questionBubble';
    }
    if (from === 'user') {
        styleName = 'userBubble';
    } else if (from === 'pharmacy') {
        styleName = 'pharmacyBubble';
        left = false;
    }

    let textParts = text ? text.split('\n').map(function(item: string, key:  number) {
        return (
            <span className={styleName + 'Text'} key={key}>
                    {item}<br/>
                </span>
        );
    }) : undefined;

    let answers;
    if (message.answers && message.answers.length > 0) {
        answers = message.answers.map((answer: TMessageAnswer, index: number) => {
            return (
                <div
                    key={index}
                    className={answer.priority ? 'answerPriority' : 'answer'}
                    onClick={answer.onClick}
                    style={{cursor: 'pointer'}}>
                    {answer.text}
                </div>);
        });
    }
    const dispatch = useDispatch();
    const checkMessage = (order: IOrder, message: IOrderMessage) => {
        if (message.from !== 'pharmacy' && !message.readByPharmacy && message.messageId) {
            dispatch(actions.messageRead(order, message));
        }
    };

    useEffect(() => {
        checkMessage(props.order, props.message);
    } );

    return (
        <div className="orderMessageContainer">
            {!left &&
            <div className="spacer" />}
            <div className="bubbleContainer">
                <div className={styleName}>
                    <div className={styleName + 'Title'}>{title}</div>
                    {textParts &&
                    <div>{textParts}</div> }
                    {(loadStatus && loadStatus === 'loading') &&
                    <div className={styleName + 'Text'}>{'Lade Bild'}</div> }
                    {imageData &&
                    <img src={'data:image/jpeg;base64,' + imageData} className="chatImage" style={{cursor: 'pointer'}} alt='chatImage'/> }
                </div>
                <div className={styleName + 'BottomLine'}>
                    <div className={styleName + 'Date'}>
                        {date ? formatDateForOrderList(date) : null}
                    </div>
                    <div className="spacer" />
                    {answers}
                </div>

            </div>
            {left &&
            <div className="spacer" />}
        </div>
    );
};

export default OrderMessage;
