import React, { ChangeEvent } from 'react';
import ReactTooltip from 'react-tooltip';
import './ChatInputBox.scss';
// import textIcon from '../../../assets/images/textModules.png';
import sendIcon from '../../../assets/images/send.png';
import flagIcon from '../../../assets/images/flag.png';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/configureStore';
import { IOrder } from '../../../store/types/Orders';
import * as actions from '../../../store/actions/index';

interface IProps {
    order: IOrder;
}

const ChatInputBox: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();

    const text = useSelector((state: AppState) => {
        if (
            typeof state.orders.typedTexts[props.order.orderId] === 'undefined'
        ) {
            dispatch(actions.setTypedText(props.order.orderId, ''));
        }
        return state.orders.typedTexts[props.order.orderId];
    });
    const onTextChange = (text: string) => {
        dispatch(actions.setTypedText(props.order.orderId, text));
    };
    const keyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            if (event.altKey) {
                dispatch(
                    actions.setTypedText(props.order.orderId, text + '\r\n'),
                );
            } else {
                event.preventDefault();
                send(text);
            }
        }
    };

    const send = (text: string) => {
        text = text.replace(/^\s+|\s+$/g, '');
        if (text.trim().length < 1) {
            return false;
        }
        dispatch(actions.sendChatMessage(props.order, text));
        dispatch(actions.setTypedText(props.order.orderId, ''));
        return true;
    };

    const onChangeStatus = () => {
        dispatch((actions.showChangeStatus(props.order.orderId, false)));
    };
    return (
        <div className="textBoxContainer">
            <div
                className="boxWrapper"
                id="bottomInputBox"
                style={{ display: 'flex' }}
            >
                <textarea
                    name="chatInput"
                    id="chatInput"
                    cols={30}
                    rows={4}
                    placeholder="Nachricht schreiben"
                    onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                        onTextChange(event.target.value)
                    }
                    onKeyDown={keyDown}
                    value={text}
                />
                {/*<img src={textIcon} alt="text" className="textIcon" />*/}
                <img
                    src={sendIcon}
                    alt="send"
                    className="sendIcon"
                    data-tip="Nachricht versenden"
                    onClick={() => send(text)}
                />
                <img
                    src={flagIcon}
                    alt="flag"
                    className="flagIcon"
                    data-tip="Bestellstatus ändern"
                    onClick={onChangeStatus}
                />
            </div>
            <ReactTooltip
                delayShow={1000}
                place={'top'}
                effect={'solid'}
                multiline={true}
            />
        </div>
    );
};

export default ChatInputBox;
