import React from 'react';
import "./OptionItem.scss";

interface IProps {
    isSelected: boolean;
    value: string;
    text: string;
    onClick: (value: string) => void;
}
const OptionItem:React.FC<IProps> = (props: IProps) => {
    return (
        <div>
            <div className='optionItemContainer' onClick={() => props.onClick(props.value)}>
                <div className={props.isSelected ? 'optionSelected' : 'optionNotSelected'}/>
                <span className='text'> {props.text}</span>
            </div>
        </div>
    );
};

export default OptionItem;
