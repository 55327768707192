import React from 'react';

// local imports
import "./Chats.scss";
import ChatList from "./ChatList/ChatList";
import ChatView from "./ChatView/ChatView";
import {useSelector} from "react-redux";
import {AppState} from "../../store/configureStore";
import Spinner from "../UI/Spinner/Spinner";

interface IProps {
}

const Chats: React.FC<IProps> = (props: IProps) => {
    const isChatMessagesLoading = useSelector((state: AppState) => state.chats.chatMessagesLoading);
    return (
        <div className='chatsContainer'>
            <ChatList/>
            {isChatMessagesLoading ? <Spinner /> : <ChatView/>}
        </div>
    );
};

export default Chats;
