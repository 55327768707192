import React from "react";
import {ChangeEvent} from 'react';
import { connect } from "react-redux";

// local component and asset imports
import logo from "../../../assets/images/amamed-logo-blue.png";
import "./Login.scss";
import Input from "../../UI/Input/Input";
import Spinner from "../../UI/Spinner/Spinner";
import { updateObject, checkValidity } from "../../../shared/utility";
import {Auth} from "../../../store/types/Auth";
import {AppState} from "../../../store/configureStore";
import {ThunkDispatch} from "redux-thunk";
import {AuthActions} from "../../../store/types/authActionTypes";
import * as actions from "../../../store/actions";


type input = {
    elementType: string,
    elementConfig: {
        type: string,
        label: string
    },
    value: string,
    validation: {
        required?: boolean,
        isEmail?: boolean,
        minLength?: number
    },
    valid: boolean,
    touched: boolean,
    autoFocus: boolean
}

type State = {
    loginForm: {
        [key: string]: input
    },
    formIsValid: boolean
}

type Props = LinkDispatchProps & LinkStateProps;

export class Login extends React.Component<Props, State> {

    state: Readonly<State> = {
        // loginForm object is used for form definition  and form validation
        loginForm: {
            userEmail: {
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    label: 'Email Address',
                },
                value: '',
                validation: {
                    required: true,
                },
                valid: false,
                touched: false,
                autoFocus: false

            },
            password: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    label: 'Password',
                },
                value: '',
                validation: {
                    required: true,
                },
                valid: false,
                touched: false,
                autoFocus: false
            }
        },
        formIsValid: false
    };

    loginEventHandler = (event: any) => {
        event.preventDefault();
        const formData: any = {};
        for (let formElementIdentifier in this.state.loginForm) {
            formData[formElementIdentifier] = this.state.loginForm[formElementIdentifier].value;
        }

        this.props.onAuth(formData['userEmail'], formData['password']);

    };

    loginFromBackofficeTokenClicked = (event: any) => {
        event.preventDefault();
        navigator.clipboard.readText()
            .then(text => {
                console.log(text);
                // `text` contains the text read from the clipboard
                let loginData = JSON.parse(atob(text));
                let token = loginData['token'];
                console.log(loginData);
                console.log(token);
                // check for valid url
                let url = loginData['domain'];
                if (url && !url.includes('//')) {
                    url = 'http://' + url;
                }
                this.props.loginViaTokenString(url, token, 'amamed');
            })
            .catch(err => {
                // maybe user didn't grant access to read from clipboard
                console.log('Something went wrong', err);
            });
    };

    inputChangedHandler = (event: any, inputIdentifier: string) => {
        const updatedLoginFormElement = updateObject(this.state.loginForm[inputIdentifier], {
            value: event.target.value,
            valid: checkValidity(event.target.value, this.state.loginForm[inputIdentifier].validation),
            touched: true
        });
        const updatedLoginForm = updateObject(this.state.loginForm, {
            [inputIdentifier]: updatedLoginFormElement
        });
        let formIsValid = true;
        for (let inputIdentifier in updatedLoginForm) {
            formIsValid = updatedLoginForm[inputIdentifier].valid && formIsValid;
        }
        this.setState({
            loginForm: updatedLoginForm,
            formIsValid: formIsValid
        });
    };

    async componentDidMount() {
        /*let data = await loginWithCsafe();
        console.log(data);
        this.props.onAuthWithCsafe(data);*/
    }


    render() {
        // showing error message for unauthorised login credentials

        console.log('render!!');

        let errorMsg = null;
        if (this.props.error) {
            errorMsg = ( <span className="errorMsg">{this.props.error}</span>);
        }
        return (
            <div className="loginContainer">
                <div className="container">
                    <div className="header">
                        <img src={logo} alt="Logo"/>
                        <h4 className="title"></h4>
                    </div>
                    {errorMsg}
                    <form className="loginForm">

                        <Input
                            elementType={this.state.loginForm.userEmail.elementType}
                            elementConfig={this.state.loginForm.userEmail.elementConfig}
                            autoFocus={this.state.loginForm.userEmail.autoFocus}
                            value={this.state.loginForm.userEmail.value}
                            changed={(event: ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'userEmail')}
                            shouldValidate={this.state.loginForm.userEmail.validation}
                            touched={this.state.loginForm.userEmail.touched}
                            invalid={this.state.loginForm.userEmail.valid}
                        />
                        <Input
                            elementType={this.state.loginForm.password.elementType}
                            elementConfig={this.state.loginForm.password.elementConfig}
                            autoFocus={this.state.loginForm.password.autoFocus}
                            value={this.state.loginForm.password.value}
                            changed={(event: ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'password')}
                            shouldValidate={this.state.loginForm.password.validation}
                            touched={this.state.loginForm.password.touched}
                            invalid={this.state.loginForm.password.valid}
                        />
                        {this.props.loading ? <Spinner /> : null}
                        <button
                            className={this.props.loading ? "loginBtnDisabled" : "loginBtn"}
                            onClick={(event) => this.loginEventHandler(event)}
                            disabled={!this.state.formIsValid || this.props.loading}
                        >
                            Login
                        </button>
                        {/*<button
                            className='cSafeSubmit'
                            style={{width: '90%', cursor: 'pointer'}}
                            disabled={this.props.loading}
                            id="csafe"
                        >
                            Login via CSAFE
                        </button>*/}
                        <button
                            className='cSafeSubmit'
                            disabled={this.props.loading}
                            style={{width: '90%', cursor: 'pointer'}}
                            onClick={this.loginFromBackofficeTokenClicked}
                        >
                            KOPPELN SIE JETZT IHR BACKOFFICE
                        </button>
                    </form>
                </div>
            </div>
        )
    }
}

interface LinkStateProps {
    error: Auth['error'],
    loading: Auth['loading'],
    loginToken: Auth['loginToken']
}
interface LinkDispatchProps {
    onAuth: (email: string, password: string) => void;
    onAuthWithCsafe: (data: any) => void;
    loginViaTokenString: (url: string, token: string, kind: string) => void;
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
    error: state.auth.error,
    loading: state.auth.loading,
    loginToken: state.auth.loginToken
});

const mapDispatchToProps = ( dispatch: ThunkDispatch<any, any, AuthActions> ): LinkDispatchProps => ({
    onAuth: (email, password) => { dispatch(actions.auth(email, password)); },
    onAuthWithCsafe: (data) => {dispatch(actions.authWithCsafe(data))},
    loginViaTokenString: (url, token, kind) => { dispatch(actions.loginViaTokenString(url, token, kind)); }

});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
