import dateFormat, {i18n} from 'dateformat';

i18n.dayNames = [
    'Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam',
    'Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'
];
i18n.monthNames = [
    'Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez',
    'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'
];
i18n.timeNames = [
    'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
];

export const updateObject = (oldObject: any, updatedValues: any) => {
    return {
        ...oldObject,
        ...updatedValues
    };
};


export const checkValidity = (value: any, rules: any) => {
    let isValid: any = true;

    if (rules.required) {
        let convertedValue = value.toString(); // in case it is a number we convert it to string
        isValid = convertedValue.trim() !== "" && convertedValue.trim() !== null && isValid;
    }

    if (rules.isEmail) {
        const pattern = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;
        isValid = pattern.test(value) && isValid;
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid;
    }

    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid;
    }

    if (rules.isImageTypeValid) {
        const allowedImageTypes = ["image/png", "image/jpeg", "image/jpg"];
        if (value !== undefined) {
            let resultIsValid = allowedImageTypes.find(function(element) {
                return element === value.type;
            });
            isValid = resultIsValid && isValid;
        }
    }

    if (rules.isPhoneOrFaxNumber && rules.required === undefined) {
        if (value.trim() !== "") {
            const pattern = /^[+]*[0-9][-\s/0-9]*$/;
            isValid = pattern.test(value.trim()) && isValid
        } else {
            isValid = true
        }

    }

    if (rules.isPhoneOrFaxNumber && rules.required) {
        const pattern = /^[+]*[0-9][-\s/0-9]*$/;
        isValid = pattern.test(value.trim()) && isValid
    }

    return isValid;
};

export const formatDateForOrderList = (date: Date): string => {
    let compareDate = new Date();
    let timediff = Math.ceil((compareDate.getTime() - date.getTime()) / 1000);

    if (timediff <= 60) {
        return 'jetzt';
    }
    if (timediff <= 3600) {
        return 'vor ' + Math.floor(timediff / 60) + ' Min';
    }

    if (date.getFullYear() === compareDate.getFullYear() &&
        date.getMonth() === compareDate.getMonth() &&
        date.getDate() === compareDate.getDate()) {
        return 'heute ' + dateFormat(date, 'HH:MM');
    }

    let todayDiff = (compareDate.getHours() * 3600) + compareDate.getMinutes() * 60 + compareDate.getSeconds();

    if (timediff <= (86400 * 4) + todayDiff) {
        return dateFormat(date, 'dddd HH:MM');
    }

    if (date.getFullYear() === compareDate.getFullYear()) {
        return dateFormat(date, 'HH:MM "Uhr |" dd.mm.yy');
    }

    return dateFormat(date, 'HH:MM "Uhr |" dd.mm.yy');
};

export const downloadFile = async (downloadUrl: string): Promise<Buffer> => {
    return new Promise<Buffer>(async (resolve, reject) => {
        /*let received_bytes = 0;
        let total_bytes = 0;*/
        let response = await fetch(downloadUrl, { headers: { 'pragma': 'no-cache', 'cache-control': 'no-cache', 'mode': 'no-cors' } } );

        try {
            if (response.body) {
                const reader = response.body.getReader();
                let result: Buffer = new Buffer(0);
                while (true) {
                    const data = await reader.read();
                    if (data.value) {
                        result = Buffer.concat([result, Buffer.from(data.value)]);
                    }
                    if (data.done) {
                        resolve(result);
                        return;
                    }
                }
            } else {
                reject();
            }
        }
        catch (ex) {
            console.log(ex);
            reject();
        }
    });
};


export const shortId = (len: number): string => {
    let length = len ? len : 9,
        text = '',
        possible = '23456789ABCDEFGHJKLMNPRSTUVWXYZabcdefghijkmnopqrstuvwxyz';

    for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
};
