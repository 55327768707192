import * as actionTypes from './actionTypes';
import { AuthActions } from '../types/authActionTypes';
import { Dispatch } from 'redux';
import axios from 'axios';
import {getCsafeLoginUrl, loginViaVitus} from "../../shared/socketApi";

import * as callSocketApi from '../../shared/socketApi';
import {checkPrivateKey, keyGenerator} from "../../shared/securityHelper";
import * as actions from './index';
import {sendPublicKey} from "../../shared/socketApi";
import {subscribeUser} from "../../subscription";
import {getPrivateKey} from "../../shared/csafeSocketApi";

export const authStart = (): AuthActions => {
    return {
        type: actionTypes.AUTH_START,
    };
};

export const authSuccess = (loginToken: string, pharmacyName: string, pharmacyId: string): AuthActions => {
    localStorage.setItem('loginToken', loginToken);
    return {
        type: actionTypes.AUTH_SUCCESS,
        loginToken,
        pharmacyName,
        pharmacyId,
    }
};

export const authFail = (err: string): AuthActions => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: err,
    };
};

export const authLogout = (): AuthActions => {
    setTimeout(() => {
       callSocketApi.disconnect();
    }, 0);
    console.log('auth logout called');
    localStorage.removeItem('loginToken');
    localStorage.removeItem('csafeObjectId');
    localStorage.removeItem('csafeConnectionId');
    return {
        type: actionTypes.AUTH_LOGOUT,
    };
};

export const auth = (email: string, password: string) => {
    return async (dispatch: Dispatch<AuthActions>) => {
        dispatch(authStart());
        // step-1: try login with email and password
        // step-2: after successful login check if private key is available in localstorage or not
        // step-3: if private key available we check the key and show user all orders
        // step-4: if private key not available we ask user to import private key from local hard drive
        // step-5: we update localStorage with private key and then show all orders
        callSocketApi.initWsConnection();
        await callSocketApi.connect();
        const result = await callSocketApi.login(email, password);
        // console.log(result);
        if (result && result.loginToken !== null) {
            // here we check if user need public key for server
            // needPublicKey = 0 (OK)  and 1(NEED KEY)
            if (result.needPublicKey === 1) {
                // public key not available in server so user have to generate new key pair
                console.log('public key missing in server');
                dispatch(publicKeyNotFound());
            } else {
                // public key available in server so user should have private key
                let privateKey = localStorage.getItem('privateKey');
                if (privateKey) {
                    let response: any = await checkPrivateKey(privateKey);
                    // console.log(response);
                    if (response) {
                        dispatch(setPharmacyData(response.pharmacyIdf, response.pharmacyCity, response.pharmacyZip));
                    }
                    if (response.isValid) {
                        dispatch(privateKeyFound());
                        let isBackupDone = localStorage.getItem('isBackupDone') === '1';
                        if (!isBackupDone) {
                            dispatch(authSuccess(result.loginToken, result.pharmacyName, result.pharmacyId));
                            dispatch(showBackupKeyView());
                            return;
                        }
                        // ===== Push notification subscription ===========
                            subscribeUser(result.pharmacyId);
                        // ==== END of push notification subscription =====
                        dispatch(actions.getAllOrders() as any);
                        // dispatch(actions.getChatList() as any);
                    } else {
                        dispatch(privateKeyNotFound())
                    }
                } else {
                    dispatch(privateKeyNotFound())
                }
            }
            dispatch(authSuccess(result.loginToken, result.pharmacyName, result.pharmacyId));

        } else {
            dispatch(
                authFail(
                    'Fehlerhafte Logindaten, die Kombination aus Benutzername und Password ist ungültig.',
                ),
            );
        }
    };
};

export const checkPrivateKeyAndLoadOrders = (privateKey: string, upload: boolean, firstTimeUser: boolean) => {
    return async (dispatch: Dispatch<AuthActions>) => {
        if (upload) {
            await sendPublicKey(privateKey);
        }
        let response: any = await checkPrivateKey(privateKey);
        console.log(response);
        if (response) {
            dispatch(setPharmacyData(response.pharmacyIdf, response.pharmacyCity, response.pharmacyZip));
        }
        if (response.isValid) {
            localStorage.setItem('isBackupDone', '1');
            dispatch(savePrivateKey(privateKey));
            dispatch(publicKeyFound());
            dispatch(privateKeyFound());

            localStorage.setItem('showBackupKeyPrompt', '0');
            dispatch(actions.setShowBackupKeyPrompt(false));
            // we show csafe modal if only csafe login happens
            if (localStorage.getItem('csafeConnectionId')) {
                dispatch(showCsafeModal());
            } else {
                localStorage.setItem('privateKey', privateKey);
            }
            if (firstTimeUser) {
                localStorage.setItem('isBackupDone', '0');
                dispatch(showBackupKeyView());
                return;
            }
            dispatch(actions.getAllOrders() as any);
            // dispatch(actions.getChatList() as any);
        } else {
            dispatch(privateKeyNotFound());
            dispatch(privateKeyInvalid());
        }
    };
};

export const startCreateRSAKey = (): AuthActions => ({
    type: actionTypes.START_CREATE_RSA_KEY
});

export const endCreateRSAKey = (): AuthActions => ({
    type: actionTypes.END_CREATE_RSA_KEY
});

export const createRSAKey = () => {
    return async (dispatch: Dispatch<AuthActions>) => {
        // setImmediate(() => dispatch(startCreateRSAKey()));

        let key = await keyGenerator();
        console.log(key);
        if (key) {
            dispatch(endCreateRSAKey());
            dispatch(checkPrivateKeyAndLoadOrders(key.privateKey, true, true) as any);
        }
    };
};

export const privateKeyFound = (): AuthActions => ({
    type: actionTypes.PRIVATE_KEY_FOUND
});

export const privateKeyNotFound = (): AuthActions => ({
    type: actionTypes.PRIVATE_KEY_NOT_FOUND
});

export const privateKeyInvalid = (): AuthActions => ({
    type: actionTypes.PRIVATE_KEY_INVALID
});

export const publicKeyFound = (): AuthActions => ({
    type: actionTypes.PUBLIC_KEY_FOUND
});

export const publicKeyNotFound = (): AuthActions => ({
    type: actionTypes.PUBLIC_KEY_NOT_FOUND
});

export const rsaKeyLoading = (): AuthActions => ({
    type: actionTypes.RSA_KEY_LOADING
});

export const rsaKeyLoadingDone = (): AuthActions => ({
    type: actionTypes.RSA_KEY_LOADING_DONE
});

export const showBackupKeyView = (): AuthActions => ({
    type: actionTypes.SHOW_BACKUP_KEY_VIEW
});

export const setShowBackupKeyPrompt = (value: boolean): AuthActions => ({
    type: actionTypes.SHOW_BACKUP_PROMPT_KEY,
    value: value
});

export const hideBackupKeyView = (): AuthActions => ({
    type: actionTypes.HIDE_BACKUP_KEY_VIEW
});

export const authCheckState = () => {
    // --------------------------------
    // ------- Auto login steps -------
    // --------------------------------
    // step-1: First we check if loginToken is stored in localstorage or not
    // step-2: loginToken available => try login with login token
    // step-3: loginToken not available => logout user if logged it
    // step-4: after successful login check if private key is available in localstorage or not
    // step-5: if private key available we check the key and show user all orders
    // step-6: if private key not available we ask user to import private key from local hard drive
    // step-7: we update localStorage with private key and then show all orders
    return async (dispatch: Dispatch<AuthActions>) => {

        // console.log('auto Login called ....');
        const loginToken = localStorage.getItem('loginToken');

        if (!loginToken) {
            // console.log('login token not found in localstorage');
            dispatch(authLogout());
            dispatch(actions.resetData() as any);
            dispatch(actions.resetChatData() as any);
        } else {
            dispatch(rsaKeyLoading());
            dispatch(authSuccess(loginToken, '', ''));
            callSocketApi.initWsConnection();
            await callSocketApi.connect();
            const result = await callSocketApi.loginWithToken(loginToken);


            if (result.errorCode !== 0) {
                // if someone play with the localstorage loginToken and change it
                dispatch(authFail('Fehlerhafte Logindaten.'));
                dispatch(authLogout());
                dispatch(actions.resetData() as any);
                dispatch(actions.resetChatData() as any);
            } else {

                if (result.needPublicKey === 1) {
                    console.log('public key not found in server');
                    dispatch(publicKeyNotFound());
                } else {

                    let privateKey = await getPrivateKey();

                    if (privateKey) {
                        let response: any = await checkPrivateKey(privateKey);
                        if (response) {
                            dispatch(setPharmacyData(response.pharmacyIdf, response.pharmacyCity, response.pharmacyZip));
                        }
                        if (response.isValid) {
                            dispatch(privateKeyFound());
                            dispatch(savePrivateKey(privateKey));
                            let isBackupDone = localStorage.getItem('isBackupDone') === '1';
                            if (!isBackupDone && !localStorage.getItem('csafeConnectionId')) {
                                dispatch(showBackupKeyView());
                                return;
                            }
                            // ========= Push Notification Subscription ==============
                                subscribeUser(result.pharmacyId);
                            // ======== END OF Push Notification Subscription ========
                            dispatch(actions.getAllOrders() as any);
                            // dispatch(actions.getChatList() as any);

                        } else {
                            dispatch(privateKeyNotFound());
                        }
                    } else {
                        dispatch(privateKeyNotFound());
                    }
                }
                dispatch(authSuccess(loginToken, result.pharmacyName, result.pharmacyId));
            }
            dispatch(rsaKeyLoadingDone());
        }
    };
};

export const setPharmacyData = (pharmacyIdf: string, pharmacyCity: string, pharmacyZip: string): AuthActions => ({
    type: actionTypes.SET_PHARMACY_DATA,
    pharmacyIdf: pharmacyIdf,
    pharmacyCity: pharmacyCity,
    pharmacyZip: pharmacyZip
});

export const authWithCsafe = (data: any) => {
    return (dispatch: Dispatch<AuthActions>) => {
        console.log('auth with csafe');
        dispatch(authStart());
        const authData: any = {
            url: getCsafeLoginUrl(),
            method: 'POST',
            data: data,
            responseType: 'json',
            validateStatus: null,
        };
        axios(authData)
            .then((response) => {
                console.log(response);
                if(response !== undefined) {
                    if (response.status === 200) {
                        localStorage.setItem('loginToken', response.data.loginToken);
                        localStorage.setItem('csafeConnectionId', response.data.csafeConnectionId);
                        dispatch(authCheckState() as any);
                    }
                }

            })
            .catch(err => {
                console.log(err);
                dispatch(authFail(err.response));
            })
    }
};

export const loginViaTokenString = (url: string, token: string, kind: string) => {
    return async (dispatch: Dispatch<AuthActions>) => {
        console.log('auth with token string');
        callSocketApi.initWsConnection();
        await callSocketApi.connect();
        dispatch(authStart());

        if (kind === 'amamed') {
            const authData: any = {
                url: process.env.REACT_APP_APOZEPT_SERVICE_URL + 'api/v2/amamed/loginPharmacy',
                method: 'POST',
                data: {
                    'amamedPortalLoginToken': token
                },
                responseType: 'json',
                validateStatus: null,
            };
            axios(authData)
                .then((response) => {
                    console.log(response);
                    if (response !== undefined) {
                        if (response.status === 200) {
                            localStorage.setItem('loginToken', response.data.apozeptLoginToken);
                            dispatch(authCheckState() as any);
                        }
                    }

                })
                .catch(err => {
                    console.log(err);
                    dispatch(authFail(err.response));
                })
        } else {

            let response = await callSocketApi.loginViaVitus(url, token, kind);
            console.log('loginViaVitusAuth', response);

            if (response.errorCode === 0) {

                // authentication successful so we save loginToken
                let saveData = {'loginToken': response.loginToken};
                localStorage.setItem('loginToken', saveData.toString());
                dispatch(authCheckState() as any);

            } else if (response.status === 404) {
                dispatch(authFail('Cannot connect to server.'));
            } else {
                dispatch(authFail('Could not authenticate'));
            }
        }
    }
};

export const showCsafeModal = (): AuthActions => ({
    type: actionTypes.SHOW_CSAFE_MODAL
});

export const hideCsafeModal = (): AuthActions => ({
    type: actionTypes.HIDE_CSAFE_MODAL
});

export const savePrivateKey = (key: string): AuthActions => ({
    type: actionTypes.SAVE_PRIVATE_KEY,
    privateKey: key
});
