import React from 'react';
import Modal from "../Modal/Modal";
import Spinner from "../Spinner/Spinner";
import {useSelector} from "react-redux";
import {AppState} from "../../../store/configureStore";

interface IProps {
}

const LoadingData: React.FC<IProps> = (props: IProps) => {
    const isOrdersLoading = useSelector((state: AppState) => state.orders.loading);
    const isChatLoading = useSelector((state: AppState) => state.chats.chatListLoading);
    return (
        <Modal
            show={isOrdersLoading || isChatLoading}
            modalClosed={() =>{}}
        >
            <div className="modalContainer">
                <div className="header">
                    <h4 className="title">
                        Loading...
                    </h4>
                </div>
                <Spinner />
            </div>
        </Modal>
    );
};

export default LoadingData;
