import React from 'react';
import "./ChatView.scss";
import ChatMessagesView from "../ChatMessagesView/ChatMessagesView";
import ChatMessagesInputView from "../ChatMessageInputView/ChatMessagesInputView";
import {useSelector} from "react-redux";
import {AppState} from "../../../store/configureStore";

interface IProps {
}

const ChatView: React.FC<IProps> = (props: IProps) => {
    const chatSelected = useSelector((state: AppState) => state.chats.selectedChatBucketId !== null);
    return (
        <div className="chatViewContainer">
            {
                chatSelected ?
                <React.Fragment>
                    <div className="upperView">
                        <ChatMessagesView />
                    </div>
                    <ChatMessagesInputView/>
                </React.Fragment> :
                    <div className='infoMsg'>Klicken Sie auf eine Chat, um Details anzuzeigen.</div>
            }
        </div>
    );
};

export default ChatView;
