import * as React from "react";

import "./Modal.scss";

import Backdrop from "../Backdrop/Backdrop";

interface IProps {
    show: boolean;
    modalClosed: () => void;
    className?: string;
    children?: React.ReactNode;
}

class Modal extends React.Component<IProps> {
    shouldComponentUpdate(nextProps: any, nextState: any) {
        return (
            nextProps.show !== this.props.show ||
            nextProps.children !== this.props.children
        );
    }

    render() {
        let modalStyle = {
            transform: this.props.show
                ? "translateY(0)"
                : "translateY(-100vh)",
            opacity: this.props.show ? 1 : 0
        };
        let className = `Modal ${this.props.className}`;
        return (
            <React.Fragment>
                <Backdrop
                    show={this.props.show}
                    clicked={this.props.modalClosed}
                />
                <div
                    className={className}
                    style={modalStyle}
                >
                    {this.props.children}
                </div>
            </React.Fragment>
        );
    }
}

export default Modal;
