import React from 'react';
import { connect } from 'react-redux'
import * as actions from '../../../store/actions/index';
import {  } from 'react-router-dom';

type Props = LinkDispatchProps;


class Logout extends React.Component<Props> {
    componentDidMount() {
        this.props.onLogout();
    }

    render() {
        return null;
    };

};

interface LinkDispatchProps {
    onLogout: () => void;
}

const mapDispatchToProps = (dispatch: any): LinkDispatchProps => ({
    onLogout: () => {
        dispatch(actions.authLogout());
        dispatch(actions.resetData());
        dispatch(actions.resetChatData());
    }
});

export default connect(null, mapDispatchToProps)(Logout);
