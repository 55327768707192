import React from "react";
import "./Input.scss";

interface IProps {
    elementType: string;
    elementConfig: {
        type?: string;
        label: string;
        options?: any[];
    };
    value: string | number;
    changed: (event: any) => void;
    invalid: boolean;
    shouldValidate: {};
    touched: boolean;
    autoFocus: boolean;
    disabled?: boolean;
    placeholder?: string;
}



const Input: React.FC<IProps> = (props: IProps) => {
    let inputElement: any;

    switch (props.elementType) {
        case "input":
            inputElement = (
                <input
                    {...props.elementConfig}
                    value={props.value}
                    onChange={props.changed}
                    autoFocus={props.autoFocus}
                    placeholder={props.elementConfig.label}
                    className="inputText"
                />
            );

            break;
        default:
            inputElement = (
                <input
                    {...props.elementConfig}
                    value={props.value}
                    onChange={props.changed}
                    autoFocus={props.autoFocus}
                    placeholder={props.elementConfig.label}
                    className="inputText"
                />
            );
    }

    return (
        <React.Fragment>
            {inputElement}
        </React.Fragment>
    );
};

export default Input;
