import { Chat } from "../types/Chat";
import { ChatActions } from "../types/chatActionTypes";
import {shortId, updateObject} from "../../shared/utility";
import * as actionTypes from "../actions/actionTypes";
import {closeChat} from "../../shared/socketApi";

const initialState: Chat = {
    showChatView: false,
    chatBucketList: {},
    selectedChatBucketId: null,
    chatListLoading: false,
    chatMessagesLoading: false,
    typedTexts: {},
    error: null,
};

const getChatListStart = (state: Chat, action: any) => {
    return updateObject(state, {chatListLoading: true, error: null});
};

const getChatListFail = (state: Chat, action: any) => {
    return updateObject(state, {chatListLoading: false, error: 'ChatList Load failed.'});
};

const getChatListSuccess = (state: Chat, action: any) => {
    return updateObject(state, { chatListLoading: false, chatBucketList: action.buckets});
};

const messagesLoadingStart = (state: Chat, action: any) => {
    return updateObject(state, {chatMessagesLoading: true});
};

const messagesLoadingEnd = (state: Chat, action: any) => {
    return updateObject(state, {chatMessagesLoading: false});
};

const setChatMessages = (state: Chat, action: any) => {
    let messages: any = action.messages;
    return {
        ...state,
        chatBucketList: {
            ...state.chatBucketList,
            [action.bucketId]: {
                ...state.chatBucketList[action.bucketId],
                messages: messages,
                latestElement: {
                    ...state.chatBucketList[action.bucketId].latestElement,
                  elementId: action.latestElementId
                },
                isMessagesLoaded: true
            }
        }
    };
};

const toggleShowChatView = (state: Chat, action: any) => {
    return updateObject(state, {showChatView: true});
};

const hideChatView = (state: Chat, action: any) => {
    return updateObject(state, {showChatView: false})
};

const chatSelected = (state: Chat, action: any) => {
    return updateObject(state, {selectedChatBucketId: action.bucketId})
};

const setTypedChatText = (state: Chat, action: any) => {
    let typedTexts = state.typedTexts;
    typedTexts[action.bucketId] = action.text;
    return {...state, typedTexts: typedTexts};
};

const addIncomingChatMessage = (state: Chat, action: any) => {
    let newMessage = action.message;
    let chat = state.chatBucketList[action.bucketId];
    // console.log(chat);
    if (chat.messages) {
        newMessage = [newMessage, ...chat.messages];
    }

    return {
        ...state,
        chatBucketList: {
            ...state.chatBucketList,
            [action.bucketId]: {
                ...state.chatBucketList[action.bucketId],
                messages: newMessage,
                status: 0,
                isMessagesLoaded: true
            }
        }
    };
};

const updateChatMessageReadReceipts = (state: Chat, action: any) => {
    let updatedChatBuckets: any = {...state.chatBucketList};
    let elementId: number = action.elementId; // messageId
    let pharmacyChat: any = updatedChatBuckets[action.bucketId];
    if (pharmacyChat) {
        let messages = pharmacyChat.messages;
        for ( let key in messages) {
            if (messages.hasOwnProperty(key)) {
                if (messages[key].elementId === elementId) {
                    if (action.readReceipts === 'allRead') {
                        messages[key].allRead = true;
                    }
                    if (action.readReceipts === 'allReceived') {
                        messages[key].allReceived = true;
                    }
                }
            }
        }
        return {
            ...state,
            chatBucketList: {
                ...state.chatBucketList,
                [action.bucketId]: {
                    ...state.chatBucketList[action.bucketId],
                    messages: messages,
                }
            }
        };
    } else {
        return state;
    }

};

const addChatBadgeCount = (state: Chat, action: any) => {
    let selectedChatBucket: any = {...state.chatBucketList[action.bucketId]},
        newBadgeCount = selectedChatBucket.unreadElements + 1;
    return {
        ...state,
        chatBucketList: {
            ...state.chatBucketList,
            [action.bucketId]: {
                ...state.chatBucketList[action.bucketId],
                unreadElements: newBadgeCount,
                lastChange: new Date().getTime()
            }
        }
    };
};

const resetChatBadgeCount = (state: Chat, action: any) => {
    return {
        ...state,
        chatBucketList: {
            ...state.chatBucketList,
            [action.bucketId]: {
                ...state.chatBucketList[action.bucketId],
                unreadElements: 0
            }
        }
    };
};

const addNewChatBucket = (state: Chat, action: any) => {
    let newBucket = action.bucket;
    newBucket['isMessagesLoaded'] = true;
    let updatedChatBucketList = {...state.chatBucketList};
    updatedChatBucketList[action.bucket.bucketId] = newBucket;
    return {
        ...state,
        chatBucketList: updatedChatBucketList
    };
};

const setChatClose = (state: Chat, action: any) => {
    setTimeout(() => {
        let clientTransactionId = shortId(9);
        closeChat({bucketId: action.bucketId,clientTransactionId: clientTransactionId})
    }, 0);
    let updateChatBuckets = {...state.chatBucketList};
    delete updateChatBuckets[action.bucketId];
    return {
        ...state,
        chatBucketList: updateChatBuckets,
        selectedChatBucketId: null
    };
};

const resetChatData = (state: Chat, action: any) => {
    return {
        ...state,
        showChatView: false,
        chatBucketList: {},
        selectedChatBucketId: null,
        chatListLoading: false,
        chatMessagesLoading: false,
        typedTexts: {},
        error: null,
    };
};

const reducer = (state = initialState, action: ChatActions): Chat => {
    switch (action.type) {
        case actionTypes.GET_CHAT_LIST_START:
            return getChatListStart(state, action);
        case actionTypes.GET_CHAT_LIST_FAIL:
            return getChatListFail(state, action);
        case actionTypes.GET_CHAT_LIST_SUCCESS:
            return getChatListSuccess(state, action);
        case actionTypes.SET_CHAT_MESSAGES:
            return setChatMessages(state, action);
        case actionTypes.TOGGLE_SHOW_CHAT_VIEW:
            return toggleShowChatView(state, action);
        case actionTypes.HIDE_CHAT_VIEW:
            return hideChatView(state, action);
        case actionTypes.CHAT_SELECTED:
            return chatSelected(state, action);
        case actionTypes.MESSAGES_LOADING_START:
            return messagesLoadingStart(state, action);
        case actionTypes.MESSAGES_LOADING_END:
            return messagesLoadingEnd(state, action);
        case actionTypes.SET_TYPED_CHAT_TEXT:
            return setTypedChatText(state, action);
        case actionTypes.ADD_INCOMING_CHAT_MESSAGE:
            return addIncomingChatMessage(state, action);
        case actionTypes.ADD_CHAT_BADGE_COUNT:
            return addChatBadgeCount(state, action);
        case actionTypes.RESET_CHAT_BADGE_COUNT:
            return resetChatBadgeCount(state, action);
        case actionTypes.ADD_NEW_CHAT_BUCKET:
            return addNewChatBucket(state, action);
        case actionTypes.UPDATE_CHAT_MESSAGE_READ_RECEIPTS:
            return updateChatMessageReadReceipts(state, action);
        case actionTypes.SET_CHAT_CLOSE:
            return setChatClose(state, action);
        case actionTypes.RESET_CHAT_DATA:
            return resetChatData(state, action);
        default:
            return state;
    }
};

export default reducer;
