// import csafe from "../components/Auth/Login/csafe";
// import csafe from "../components/Auth/Login/csafeClass";

export const storePrivateKey = (data) => {
    return new Promise(async (resolve) => {
        resolve(await window.csafe.addObject(data.key, data.connectionId, data.name, data.contentType));
    });
};

export const getPrivateKey = () => {
    return new Promise(async (resolve) => {
        let privateKey = localStorage.getItem('privateKey');
        let connectionId = localStorage.getItem('csafeConnectionId');
        if (connectionId && !privateKey) {
            let resultObject = await window.csafe.getObject('privateKey.key', connectionId);
            if ( resultObject && resultObject.objectId) {
                localStorage.setItem('csafeObjectId', resultObject.objectId);
                privateKey = resultObject.content;
            }
        }
        resolve(privateKey);
    });
};

export const deletePrivateKey = (csafeObjectId) => {
    return new Promise((resolve) => {
        resolve(window.csafe.removeObject(csafeObjectId));
    });
};

export const loginWithCsafe = () => {
    return new Promise((resolve) => {
        window.csafe.loginInit({
            csafeButtonElementId: 'csafe',
            action: {callback: (para) => resolve(para)}
        });
    })
};
