import * as React from 'react';
import { connect } from 'react-redux';

// Local components import
import Navbar from '../../components/Navigation/Navbar/Navbar';
import PrivateKeyNotFoundView from '../../components/Auth/PrivateKeyNotFoundView/PrivateKeyNotFoundView';
import PublicKeyNotFoundView from "../../components/Auth/PublicKeyNotFoundView/PublicKeyNotFoundView";
import Orders from '../../components/Orders/Orders';
import OrderStatusChange from "../../components/Orders/OrderStatusChange/OrderStatusChange";
import LargeImageView from "../../components/UI/LargeImageView/LargeImageView";
import OrderFilter from "../../components/Orders/OrderFilter/OrderFilter";
import CsafeView from "../../components/Auth/CsafeView/CsafeView";
import LoadingData from "../../components/UI/LoadingData/LoadingData";
import Chats from "../../components/Chats/Chats";

import { AppState } from '../../store/configureStore';
import { Auth } from '../../store/types/Auth';
import { OrdersInterface } from '../../store/types/Orders';
import {Chat} from "../../store/types/Chat";
import SaveKeyPrompt from "../../components/UI/SaveKeyPrompt/SaveKeyPrompt";


interface IProps {}

type Props = IProps & LinkDispatchProps & LinkStateProps;

class Dashboard extends React.Component<Props> {

    render() {
        return (
            <div className="dashboardContainer">
                <Navbar />
                <PrivateKeyNotFoundView show={!this.props.isPrivateKeyAvailable} />
                <PublicKeyNotFoundView show={!this.props.isPublicKeyFoundInServer} />
                <SaveKeyPrompt show={(
                    this.props.showBackupKeyPrompt &&
                    this.props.isPrivateKeyAvailable &&
                    this.props.isPublicKeyFoundInServer
                )}/>
                { !this.props.isChatViewEnable && <Orders />}
                { this.props.isChatViewEnable && <Chats />}
                <OrderStatusChange selectedOrderId={this.props.selectedOrderId}/>
                {this.props.showLargeImage && ( <LargeImageView />)}
                <OrderFilter/>
                <CsafeView />
                <LoadingData/>
            </div>
        );
    }
}

interface LinkStateProps {
    isPrivateKeyAvailable: Auth['isPrivateKeyAvailable'];
    isPublicKeyFoundInServer: Auth['isPublicKeyFoundInServer'];
    selectedOrderId: OrdersInterface['selectedOrderId'];
    showLargeImage: OrdersInterface['showLargeImage'];
    isChatViewEnable: Chat['showChatView'];
    showBackupKeyPrompt: Auth['showBackupKeyPrompt'];
}

interface LinkDispatchProps {
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
    isPrivateKeyAvailable: state.auth.isPrivateKeyAvailable,
    isPublicKeyFoundInServer: state.auth.isPublicKeyFoundInServer,
    selectedOrderId: state.orders.selectedOrderId,
    showLargeImage: state.orders.showLargeImage,
    isChatViewEnable: state.chats.showChatView,
    showBackupKeyPrompt: state.auth.showBackupKeyPrompt
});


export default connect(
    mapStateToProps
)(Dashboard);
