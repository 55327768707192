import React, {ChangeEvent} from 'react';
import './ChatMessagesInputView.scss';
import sendIcon from '../../../assets/images/send.png';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/configureStore";
import * as actions from "../../../store/actions/index";
import ReactTooltip from "react-tooltip";


interface IProps {
}

const ChatMessagesInputView: React.FC<IProps> = (props: IProps) => {
    const selectedBucketId: any = useSelector((state: AppState) => state.chats.selectedChatBucketId);
    const dispatch = useDispatch();

    const text = useSelector((state: AppState) => {
        return state.chats.typedTexts[selectedBucketId] ? state.chats.typedTexts[selectedBucketId] : '';
    });
    const onTextChange = (text: string) => {
        if (selectedBucketId){
            dispatch(actions.setTypedChatText(selectedBucketId, text));
        }
    };
    const keyDown = (event: React.KeyboardEvent) => {
        if (selectedBucketId) {
            if (event.key === 'Enter') {
                if (event.altKey) {
                    dispatch(actions.setTypedChatText(selectedBucketId, text + '\r\n'));
                } else {
                    event.preventDefault();
                    send(text);
                }
            }
        }
    };

    const send = (text: string) => {
        if (text && text !== '') {
            text = text.replace(/^\s+|\s+$/g, '');
            if (text.trim().length < 1) {
                return false;
            }
            if (selectedBucketId) {
                dispatch(actions.sendNewChatMessage(selectedBucketId, text));
                dispatch(actions.setTypedChatText(selectedBucketId, ''));
                return true;
            }
        }
    };

    if (selectedBucketId) {
        return (
            <div className='chatMessagesInputViewContainer'>
                <div className='textBoxContainer'>
                    <div
                        className='boxWrapper'
                        style={{ display: 'flex' }}
                    >
                        <textarea
                            className='chatInput'
                            cols={30}
                            rows={4}
                            placeholder='Nachricht schreiben'
                            value={text}
                            onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                                onTextChange(event.target.value)
                            }
                            onKeyDown={keyDown}
                        />
                        <img
                            src={sendIcon}
                            alt='send'
                            className='sendIcon'
                            data-tip="Nachricht versenden"
                            onClick={() => send(text)}
                        />
                    </div>
                </div>
                <ReactTooltip
                    delayShow={1000}
                    place={'top'}
                    effect={'solid'}
                    multiline={true}
                />
            </div>
        );
    } else {
        return (<div/>);
    }

};

export default ChatMessagesInputView;
