import React from 'react';
import ViewSelector from "../../Navigation/ViewSelector/ViewSelector";
import "./ChatList.scss";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/configureStore";
import ChatListEntry from "../ChatListEntry/ChatListEntry";
import {formatDateForOrderList} from "../../../shared/utility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as actions from "../../../store/actions/index";

interface IProps {
}

const ChatList: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const onSelectChatBucket = async (bucketId: string) => {
        dispatch(actions.chatSelected(bucketId));
        dispatch(actions.initChatMessages(bucketId));
    };
    const selectedChatBucketId = useSelector((state: AppState) => state.chats.selectedChatBucketId);

    const chatList = useSelector((state: AppState) => {
        let chatBucketList = state.chats.chatBucketList;
        let sortedBucketList = [];
        for (let bucket in chatBucketList) {
            if (chatBucketList.hasOwnProperty(bucket)) {
                chatBucketList[bucket].bucketId = bucket;
                sortedBucketList.push(chatBucketList[bucket]);
            }
        }
        sortedBucketList = sortedBucketList.filter((item: any) => {
            return item.status !== 1 && item.lastChange && item.latestElement.elementId;
        });
        sortedBucketList = sortedBucketList.sort((a: any, b: any) => {
            let latestElementA = a.lastChange !== null ? a.lastChange : new Date();
            let latestElementB = b.lastChange !== null ? b.lastChange : new Date();
            return latestElementB - latestElementA;
        });
        return sortedBucketList;
    });
    let bucketArray: any;
    bucketArray = chatList.map((bucket: any, key: number) => {
        return (
            <ChatListEntry
                key={key}
                customerName={bucket.displayUserName}
                onSelectChatBucket={() => onSelectChatBucket(bucket.bucketId)}
                date={formatDateForOrderList(new Date(bucket.lastChange))}
                lastMessage='Latest message...'
                isChatSelected={selectedChatBucketId === bucket.bucketId}
                noOfUnreadMsg={bucket.unreadElements}
            />
        );
    });

    return (
        <div className="chatListContainer chatListContainer_delayed">
            <div className="chatListContent">
                <ViewSelector selectionChanged={() => {dispatch(actions.chatSelected(null));}} />
                <div className="chatFilterContainer">
                    <span className='sortType'>
                        <FontAwesomeIcon
                            icon='caret-down'
                            color='#747474'
                        />
                        <span style={{marginLeft: 4}}>Letzte</span>
                    </span>

                </div>
                {bucketArray}
            </div>
        </div>
    );
};

export default ChatList;
