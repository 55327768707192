import React from 'react';
import Modal from "../Modal/Modal";
import logo from "../../../assets/images/amamed-logo-blue.png";
import * as FileSaver from "file-saver";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/configureStore";
import * as actions from "../../../store/actions/index";
interface IProps {
    show: boolean;
    hide: () => void;
}

const BackupKey:React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const allOrders = useSelector((state: AppState) => state.orders.allOrders);
    // ======= Backup key view methods =========
    const backupKey = () => {
        localStorage.setItem('isBackupDone', '1');
        let key: any = localStorage.getItem('privateKey');
        let file = new File([key], "privateKey.key", {type: "text/plain;charset=utf-8"});
        FileSaver.saveAs(file);
        if (Object.keys(allOrders).length === 0) {
            dispatch(actions.getAllOrders() as any);
            // dispatch(actions.getChatList() as any);
        }
        props.hide();
    };
    return (
        <Modal
            show={props.show}
            modalClosed={props.hide}
        >
            <div className="modalContainer">
                <div className="header">
                    <img src={logo} alt="Logo" />
                    <h4 className="title">
                        Schlüssel sichern
                    </h4>
                </div>

                <p className="description">
                    Sichern Sie Ihren Schlüssel.
                </p>

                <div className="selectBox" onClick={backupKey}>
                    <div className="headline">
                        Auf lokale Festplatte sichern
                    </div>
                    <div className="desc">
                        Der Schlüssel wird als Datei exportiert
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default BackupKey;
