import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
//local imports
import './OrderView.scss';
import ChatInputBox from '../../UI/ChatInputBox/ChatInputBox';
import OrderMessagesView from '../OrderMessagesView/OrderMessagesView';
import OrderItemsView from '../OrderItemsView/OrderItemsView';
import { AppState } from '../../../store/configureStore';
import { IOrder } from '../../../store/types/Orders';
import * as actions from '../../../store/actions/index';

interface IProps {}

const OrderView: React.FC<IProps> = () => {
    const messagesEnd = React.useRef<HTMLDivElement>(null);

    const isPharmacyHasKey = useSelector((state: AppState) => {
        return state.auth.isPrivateKeyAvailable
    });
    const selectedOrderId = useSelector(
        (state: AppState) => state.orders.selectedOrderId,
    );
    const orderLoading = useSelector((state: AppState) => state.orders.loading);
    const selectedOrder: IOrder = useSelector((state: AppState) => {
        return state.orders.allOrders[selectedOrderId];
    });
    const scrollToBottom = () => {
        if (messagesEnd.current !== null) {
            messagesEnd.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    useEffect(scrollToBottom, [selectedOrder]);
    const dispatch = useDispatch();
    // console.log('selected order', selectedOrder);
    if (selectedOrder && selectedOrder.imageStatus === 'notLoaded') {
        setTimeout(() => {
            dispatch(actions.prepareOrderImagesDownload(selectedOrder));
        }, 0);
    }
    let allOrdersCount = useSelector((state: AppState) => Object.keys(state.orders.allOrders).length);
    return (
        <div className="orderViewContainer">
            {selectedOrder ? (
                <React.Fragment>
                    <div className="upperView">
                        <OrderItemsView order={selectedOrder} />
                        <OrderMessagesView order={selectedOrder} />
                        <div ref={messagesEnd} /> {/* === dummy div for auto scroll to bottom  */}
                    </div>
                    <ChatInputBox order={selectedOrder}/>
                </React.Fragment>
            ) : (orderLoading || !isPharmacyHasKey) ? null :
                <div className="infoMsg">
                    {allOrdersCount > 0 ?'Klicken Sie auf eine Bestellung, um Details anzuzeigen.' : 'No order found.'}
                </div>
            }
        </div>
    );
};

export default OrderView;

