import React, {useState} from 'react';
import Modal from "../Modal/Modal";
import warningIcon from "../../../assets/images/warning-red.png";
import * as FileSaver from "file-saver";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/configureStore";
import * as actions from "../../../store/actions/index";
import './SaveKeyPrompt.scss';

interface IProps {
    show: boolean;
}

const SaveKeyPrompt:React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const allOrders = useSelector((state: AppState) => state.orders.allOrders);

    const [doNotAskPromptValue, setDoNotAskPromptValue] = useState(false);

    // ======= Backup key view methods =========
    const backupKey = () => {

        if (doNotAskPromptValue){
            localStorage.setItem('showBackupKeyPrompt', '0');
        }

        localStorage.setItem('isBackupDone', '1');
        let key: any = localStorage.getItem('privateKey');
        let file = new File([key], "privateKey.key", {type: "text/plain;charset=utf-8"});
        FileSaver.saveAs(file);

        dispatch(actions.setShowBackupKeyPrompt(false));

        if (Object.keys(allOrders).length === 0) {
            dispatch(actions.getAllOrders() as any);
            // dispatch(actions.getChatList() as any);
        }
    };

    const handleCancelButton = () => {
        if (doNotAskPromptValue){
            localStorage.setItem('showBackupKeyPrompt', '0');
        }
        dispatch(actions.setShowBackupKeyPrompt(false));
    };

    const handleDoNotAskBox = (event: any) => {
        setDoNotAskPromptValue(event.target.checked);
    };

    return (
        <Modal
            show={props.show}
            modalClosed={() => {
                dispatch(actions.setShowBackupKeyPrompt(false));
            }}
        >
            <div className="promptModalContainer">
                <div className="promptHeader">
                    <img src={warningIcon} alt="warningIcon" />
                </div>

                <p className="description">
                    Sichern Sie unbedingt den privaten Schlüssel auf ein USB- oder Netzwerkspeicher.
                    Verlieren Sie den Schlüssel (z.B. Daten vom Browser löschen) können sie nicht mehr die Bestellungen einsehen!
                </p>

                <div className={"row"}>
                    <button className={"cancelButton"} onClick={handleCancelButton}>Nicht speichern</button>
                    <button className={"saveButton"} onClick={backupKey}>Jetzt speichern</button>
                </div>

                <input type={"checkbox"} name={"do_not_ask_check"} onChange={handleDoNotAskBox}/>Diese Info nicht mehr anzeigen
            </div>
        </Modal>
    );
};

export default SaveKeyPrompt;
